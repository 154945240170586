<template>
    <div>
      <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
      <el-table-column label="图片" width="200" prop="url">
        <template #default="scope">
          <el-image :src="`https://colorflow-cdn.rabigame.com/${scope.row.cover}`" style="width: 150px"
                    lazy></el-image>
        </template>
      </el-table-column>
      <el-table-column label="类型" prop="type" width="150">
        <template #default="scope">
          {{ cover_types[scope.row.type] }}
        </template>
      </el-table-column>
<!--      <el-table-column label="类型" prop="type" width="100"></el-table-column>-->
      <el-table-column label="地址" prop="cover" width="200"></el-table-column>
      <el-table-column label="操作" width="250">
        <template #default="scope">
          <el-button type="success" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
       <el-form :model="form" ref="form" label-width="100px">

         <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请选择分类'}]">
           <el-select v-model="form.type" placeholder="类型" clearable style="width: 150px">
             <el-option v-for="(v, k) in cover_types" :label="v" :value="k"></el-option>
           </el-select>
         </el-form-item>

         <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
           <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                      accept="image/*" :data="{prefix: 'cover'}" :onProgress="handleProgress">
             <img v-if="form.cover" :src="`https://colorflow-cdn.rabigame.com/${form.cover}`"
                  style="width: 200px">
             <el-button size="small" v-else :disabled="loading">上传</el-button>
           </el-upload>
         </el-form-item>
       </el-form>

        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../libs/utils";
import {initI18n} from '../libs/init';

export default {
    name: "Cover",
    data() {
        return {
          loading: false, data: [], dialog_opened: false, editing: null,
          cover_types: {1: '问号图封面'},
          form: {
                cover: '',
                type: 0,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/cover`).then(res => {
                this.data = res.data.data.cover_list;
                this.loading = false;
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form.i18n_name = initI18n();
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post(`/cms/v1/cover`, this.form).then(_ => {
                      this.init();
                      this.reset();
                    });
                }
            })
        },
        handleSuccess(res) {
          this.form.cover = res.data.name;
          this.loading = false;
        },
        handleProgress() {
          this.loading = true;
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
<template>
    <el-dialog v-model="dialog_opened" width="80%">
        <div>
            <el-button type="primary" @click="openDialog()" :disabled="loading">创建等级</el-button>
        </div>
        <el-table :data="achievement.stages" v-loading="loading" v-if="achievement" style="margin-top: 10px">
            <el-table-column label="等级" type="index" width="60"></el-table-column>
            <el-table-column label="活跃" prop="active" width="100">
                <template #default="scope">
                    <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="阈值" prop="threshold" width="100"></el-table-column>
            <el-table-column label="奖励" prop="rewards">
                <template #default="scope">
                    <div style="display: flex;align-items: center">
                        <template v-for="r in scope.row.rewards">
                            <el-tag v-if="r.type==='HINT'" style="margin: 0 5px">
                                hint: {{ r.count }}
                            </el-tag>
                            <template v-else-if="r.type === 'IMAGE'">
                                <template v-for="image in image_list">
                                    <el-image v-if="image.id === r.image_id" lazy style="width: 100px;margin: 0 5px"
                                              :src="`${$root.cdn_url}/${image.thumbnail}`"></el-image>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                    <el-button :disabled="loading" type="danger" @click="remove(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="dialog_opened = false">关闭</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="阈值" prop="threshold" :rules="[{required: true, message: '请输入阈值', type: 'number'}]">
                <el-input-number v-model="form.threshold" :disabled="loading"></el-input-number>
            </el-form-item>
            <template v-for="(r, i) in form.rewards">
                <el-divider>奖励{{ i + 1 }}</el-divider>
                <el-form-item label="奖励类型" :prop="`rewards.${i}.type`" :rules="[{required: true, message: '请选择奖励类型'}]">
                    <el-select v-model="r.type" :disabled="loading" @change="changeType(r)">
                        <el-option label="图片" value="IMAGE"></el-option>
                        <el-option label="hint" value="HINT"></el-option>
                    </el-select>
                    <el-button v-if="i > 0" circle type="danger" @click="removeReward(i)" icon="Delete"></el-button>
                </el-form-item>
                <el-form-item v-if="r.type === 'IMAGE'" :prop="`rewards.${i}.image_id`" label="图片id"
                              :rules="[{required: true, message: '请选择图片'}]">
                    <el-select v-model="r.image_id" filterable :disabled="loading">
                        <el-option v-for="image in image_list" :value="image.id" :label="image.nickname"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-else-if="r.type === 'HINT'" :prop="`rewards.${i}.count`" :rules="[{validator}]"
                              label="hint数">
                    <el-input-number v-model="r.count" :disabled="loading"></el-input-number>
                </el-form-item>
            </template>
            <div>
                <el-button type="primary" size="small" @click="addReward" :disabled="loading">添加奖励</el-button>
            </div>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {update} from "../../libs/utils";
import axios from "ts-axios-new";
import {ElMessageBox} from 'element-plus'

export default {
    name: "AchievementStage",
    inject: ['image_list'],
    data() {
        return {
            loading: false, editing: null, achievement: null, dialog_opened: false, dialog_opened1: false,
            form: {
                achievement_id: '',
                threshold: 0,
                rewards: [],
                active: false,
            }
        }
    },
    methods: {
        init(achievement) {
            this.achievement = achievement;
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.form.achievement_id = achievement.id;
            });
        },
        openDialog(item) {
            this.dialog_opened1 = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
                if (this.form.rewards.length < 1) {
                    this.addReward();
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.editing = null;
            this.loading = this.dialog_opened1 = false;
            this.form.rewards = [];
            this.$emit('close');
        },
        changeType(reward) {
            if (reward.type === 'IMAGE') {
                reward.image_id = '';
            } else if (reward.type === 'HINT') {
                reward.count = 1;
            }
        },
        addReward() {
            this.form.rewards.push({type: ''})
        },
        removeReward(i) {
            this.form.rewards.splice(i);
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/achievement-stage/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/achievement-stage`, this.form).then(res => {
                            this.achievement.stages.push(res.data.data);
                            this.achievement.stages.sort(function (a, b) {
                                return a.threshold < b.threshold ? -1 : 1;
                            });
                            this.reset();
                        })
                    }
                }
            })
        },
        validator(rule, value, callback) {
            if (value === parseInt(value) && value > 0) {
                callback()
            } else {
                callback('请输入大于0的整数')
            }
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/achievement-stage/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定删除该等级吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/achievement-stage/${row.id}`).then(res => {
                    this.achievement.stages.splice(this.achievement.stages.indexOf(row), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            })
        },
    },
}
</script>

<style scoped>

</style>
import { root_props } from '../main';

export function has_feature_reprocess() {
    return ['celine@bidderdesk.com', 'zhiwei.huang@bidderdesk.com', 'li.tian@bidderdesk.com'].includes(root_props.user.email)
}

export function has_feature_rookie_apply() {
    return ['celine@bidderdesk.com', 'li.tian@bidderdesk.com'].includes(root_props.user.email)
}

export function is_manager() {
    return ['celine@bidderdesk.com', 'li.tian@bidderdesk.com'].includes(root_props.user.email)
}
import {createApp} from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import LoginPT from './pt/Login.vue'
import AppPT from './pt/App.vue'
import axios from 'ts-axios-new'
import {createRouter, createWebHashHistory} from 'vue-router';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {update} from './libs/utils'
import {routes} from './routes';
import {routes_pt} from './pt/routes';
import {getLocalObj} from "./libs/storage";
import {is_manager} from "./libs/permission";


const router = createRouter({
    history: createWebHashHistory(),
    routes
});

const router_pt = createRouter({
    history: createWebHashHistory(),
    routes: routes_pt
});

let login: any = null;
declare var google: any;

export const root_props = {
    // user info
    user: {
        user_id: '',
        avatar: '',
        name: '',
        email: '',
    },
    country_list: [],
    user_list: [],
    authorization: '',
    part_time_mode: false,
    cdn_url: import.meta.env.VITE_CDN_URL
};

function disable_manager_board() {
    router.beforeEach((to, from, next) => {
        if (to.path === '/part-time') {
            next('/'); // 重定向到根路径
        } else {
            next();
        }
    });
}

function init() {
    if (!is_manager()) {
        disable_manager_board()
    }
    const app = createApp(App);
    app.use(router);
    app.use(ElementPlus, {locale: zhCn});
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    const vm = app.mount('#app');
    update(vm, root_props);
}

function login_pt() {
    const app = createApp(LoginPT);
    app.use(ElementPlus, {locale: zhCn});
    app.mount('#login');
    document.getElementById('login').style.height = '100vh'
}

function init_pt() {
    disable_manager_board()
    const app = createApp(AppPT);
    app.use(router_pt);
    app.use(ElementPlus, {locale: zhCn});
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    const vm = app.mount('#app');
    update(vm, root_props);
}

window.onload = function () {
    const path = window.location.pathname;
    if(path.startsWith('/part-time')) {
        console.log("enter part time mode.");
        root_props.part_time_mode = true
        const authorization = getLocalObj('authorization', null);
        if (authorization) {
            axios.get(`/cms/v1/me`, {headers: {authorization}}).then(res => {
                const user = res.data.data
                root_props.user.name = user['name']
                axios.interceptors.request.use(function (config) {
                    config.headers['Authorization'] = authorization;
                    return config
                }, function (error) {
                    return Promise.reject(error);
                })
                root_props.authorization = authorization;
                init_pt()
            }, _ => {
                login_pt();
            })
        } else {
            login_pt();
        }
    } else {
        root_props.part_time_mode = false
        google.accounts.id.initialize({
            client_id: import.meta.env.VITE_OAUTH2_CLIENT_ID,
            callback: (response: any) => {
                axios.post('/cms/v1/oauth2/login', {
                    platform: 'google',
                    id_token: response.credential
                }).then(res => {
                    update(root_props.user, res.data.data);
                    if (login) {
                        login.unmount();
                    }
                    init();
                });
            },
            auto_select: true,
        });
        google.accounts.id.prompt((notification: any) => {
            if (notification.isDisplayMoment() && notification.isNotDisplayed()) {
                login = createApp(Login);
                login.mount('#login');
            }
        });
    }
}
import Index from '../components/Index.vue';
import Category from '../components/Category.vue';
import Daily from '../components/Daily.vue';
import Score from '../components/Score.vue';
import Tag from '../components/Tag.vue';
import Rookie from '../components/Rookie.vue';
import Update from '../components/Update.vue';
import Achievement from '../components/Achievement/Index.vue';
import DailyChallenge from "../components/DailyChallenge.vue";
import Cover from "../components/Cover.vue";
import Collection from "../components/Collection.vue";
import PartTime from "../components/PartTime.vue";

export const routes_pt = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }
];
<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建分组</el-button>
        <el-button type="success" :disabled="loading" @click="$refs.export.init()">导出配置</el-button>
    </div>
    <el-card v-loading="loading" v-for="(d, i) in data" shadow="never" :body-style="{padding: 0}"
             style="margin-top: 10px">
        <template #header>
            <div style="display: flex">
                <div>{{ d.i18n_name.EN }}</div>
                <el-switch v-model="d.active" :disabled="loading" @change="update(d)" size="small"
                           style="margin: 0 5px"></el-switch>
                <el-button :loading="loading" @click="openDialog(d)" circle icon="Edit" size="small"></el-button>
                <el-button type="success" circle icon="Top" :loading="loading" size="small"
                           :disabled="i===0" @click="moveUp(i)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                           :disabled="i===data.length - 1" @click="moveDown(i)"></el-button>
                <el-button type="danger" circle icon="Delete" :loading="loading" size="small"
                           @click="remove(d)"></el-button>
                <el-button type="primary" :loading="loading" @click="$refs.dialog.init(d)" size="small">新建成就
                </el-button>
            </div>
        </template>
        <el-table :data="d.achievements" v-if="d.achievements.length > 0">
            <el-table-column label="奖牌" prop="medal" width="150">
                <template #default="scope">
                    <el-image :src="`${$root.cdn_url}/${scope.row.medal}`"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="类型" prop="type" width="150">
                <template #default="scope">
                    {{ achievement_type_enum[scope.row.type] }}
                </template>
            </el-table-column>
            <el-table-column label="活跃" prop="active" width="100">
                <template #default="scope">
                    <el-switch v-model="scope.row.active" :disabled="loading || scope.row.stages.length === 0"
                               @change="updateAchievement(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="名称" prop="i18n_name.EN" width="150"></el-table-column>
            <el-table-column label="描述" prop="i18n_desc.EN" width="200"></el-table-column>
            <el-table-column label="等级" prop="stage" width="100">
                <template #default="scope">
                    {{ scope.row.stages.length }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button :loading="loading" @click="$refs.dialog.init(d, scope.row)" circle size="small"
                               icon="Edit"></el-button>
                    <el-button :loading="loading" circle icon="Tools" type="primary" size="small"
                               @click="$refs.stage.init(scope.row)"></el-button>
                    <el-button type="success" circle icon="Top" :loading="loading" size="small"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                               :disabled="i===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button type="danger" circle icon="Delete" :loading="loading" size="small"
                               @click="removeAchievement(d, scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="120px" :model="form">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <AchievementDialog ref="dialog"></AchievementDialog>
    <AchievementStage ref="stage"></AchievementStage>
    <AchievementExport ref="export"></AchievementExport>
</template>

<script>
import axios from "ts-axios-new";
import {update} from '../../libs/utils';
import {initI18n} from '../../libs/init';
import AchievementDialog from "./Dialog";
import {ElMessageBox} from 'element-plus'
import AchievementStage from "./Stage";
import AchievementExport from "./Export";

export default {
    name: "Achievement",
    components: {AchievementExport, AchievementStage, AchievementDialog},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null, category_list: [], image_list: [],
            achievement_type_enum: {
                type_week_finished: '连续一周完成',
                type_launch_app: '启动app',
                type_region_filled: '完成色块',
                type_share: '分享',
                type_category_: '完成某分类',
                type_image_finished: '完成图片',
            },
            form: {
                i18n_name: initI18n(),
                active: false,
            },
        }
    },
    provide() {
        return {
            category_list: this.category_list, achievement_type_enum: this.achievement_type_enum,
            image_list: this.image_list,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/achievement-group`).then(res => {
                    this.data = res.data.data.groupList;
                }),
                axios.get(`/cms/v1/category`).then(res => {
                    update(this.category_list, res.data.data.categoryList);
                }),
                axios.get(`/cms/v1/achievement-image`).then(res => {
                    update(this.image_list, res.data.data.imageList);
                }),
            ]).then(_ => {
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                } else {
                    this.editing = null;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.i18n_name = initI18n();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/achievement-group/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/achievement-group`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/achievement-group/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该分组吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/achievement-group/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/achievement-group`, {sort}).then(_ => {
                this.loading = false;
            });
        },
        updateAchievement(row) {
            this.loading = true;
            axios.put(`/cms/v1/achievement/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        removeAchievement(group, item) {
            ElMessageBox.confirm('确定要删除该成就吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/achievement/${item.id}`).then(_ => {
                    group.achievements.splice(group.achievements.indexOf(item), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
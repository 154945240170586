<template>
    <el-calendar ref="calendar" v-loading="loading">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div v-if="date_map[data.day]" @click="remove(data.day)">
                <template v-for="image in image_list">
                    <el-image v-if="date_map[data.day].image_id === image.id" style="width: 70px" lazy
                              :src="`${$root.cdn_url}/${image.thumbnail}`"></el-image>
                    <el-image v-if="date_map[data.day].image_id === image.id" style="width: 70px" lazy
                              :src="`${$root.cdn_url}/${image.finished}`"></el-image>
                </template>
            </div>
            <div v-else style="height: 70px" @click="openDialog(data.day)"></div>
        </template>
    </el-calendar>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form ref="form" label-width="0" :model="form">
            <el-form-item prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <el-radio-group v-model="form.image_id">
                    <el-radio :label="d.id" v-for="d in dataFilter">
                        <el-image lazy :src="`${$root.cdn_url}/${d.thumbnail}`"
                                  style="width: 90px"></el-image>
                        <el-image lazy :src="`${$root.cdn_url}/material/${d.key}/finished.webp`"
                                  style="width: 90px"></el-image>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate} from "../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Daily",
    data() {
        return {
            loading: false, image_list: [], date_map: {}, dialog_opened: false,
            form: {
                date: '',
                image_id: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/daily`).then(res => {
                    res.data.data.imageList.forEach(d => {
                        this.date_map[d.date] = d;
                    });
                }),
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        remove(date) {
            const today = timestampToDate(Date.now());
            // if (date <= today)
            //     return
            ElMessageBox.confirm('确定要删除这一天的图片吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/daily/${this.date_map[date].id}`).then(res => {
                    this.loading = false;
                    delete this.date_map[date];
                });
            }).catch(_ => {
            })
        },
        openDialog(date) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.form.date = date;
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post(`/cms/v1/daily`, this.form).then(res => {
                        this.date_map[res.data.data.date] = res.data.data;
                        this.reset();
                    })
                }
            })
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.image_list.filter(d => {
                for (const date in this.date_map) {
                    if (d.id === this.date_map[date].image_id) {
                        return false;
                    }
                }
                return d.module === 2 && d.status === 4;
            })
        }
    },
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: fit-content;
    width: 160px;
    min-height: var(--el-calendar-cell-width);
}

.el-radio {
    height: fit-content;
    margin: 5px;
}
</style>
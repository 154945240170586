import Index from './components/Index.vue';
import Category from './components/Category.vue';
import Daily from './components/Daily.vue';
import Score from './components/Score.vue';
import Tag from './components/Tag.vue';
import Rookie from './components/Rookie.vue';
import Update from './components/Update.vue';
import Achievement from './components/Achievement/Index.vue';
import DailyChallenge from "./components/DailyChallenge.vue";
import Cover from "./components/Cover.vue";
import Collection from "./components/Collection.vue";
import PartTime from "./components/PartTime.vue";
import Metric from "./components/Metric.vue";
import Popup from "./components/Popup.vue";
import Subscription from "./components/Subscription.vue";
import NewbieSales from "./components/NewbieSales.vue";

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    }, {
        name: 'Tag',
        path: '/tag',
        component: Tag,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Achievement',
        path: '/achievement',
        component: Achievement,
    }, {
        name: 'DailyChallenge',
        path: '/daily-challenge',
        component: DailyChallenge,
    }, {
        name: 'Cover',
        path: '/cover',
        component: Cover,
    }, {
        name: 'Collection',
        path: '/collection',
        component: Collection,
    }, {
        name: 'PartTime',
        path: '/part-time',
        component: PartTime,
    }, {
        name: 'Metric',
        path: '/metric',
        component: Metric,
    },{
        name: 'Popup',
        path: '/popup',
        component: Popup
    }, {
        name: 'Subscription',
        path: '/subscription',
        component: Subscription,
    }, {
        name: 'NewbieSales',
        path: '/newbie-sales',
        component: NewbieSales,
    },
];
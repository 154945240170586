<template>
    <el-container>
        <el-header height="64px">
            <div style="display: flex">
                <div style="height: 64px">
                    <img style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Index'})"
                         src="https://play-lh.googleusercontent.com/kJniSqlwnf6wRXOGHCsuu512sqrUz6bW_D_O0tBV13XKkD09-4NJNW5PQx1qUwJ-hcIG=w240-h480-rw">
                </div>
                <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                    ColorFlow
                    <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                </div>
            </div>
            <div>
                <el-switch style="margin: 16px" v-model="dark" @change="changeDark" class="dark-switch"
                           active-color="#44444" :active-icon="Moon" :inactive-icon="Sunny" inline-prompt></el-switch>
            </div>
        </el-header>
        <el-container style="background-color: var(--el-border-color-extra-light)" :style="{height: height + 'px'}">
            <aside-nav ref="nav">
                <aside-group v-if="is_manager()" title="管理面板">
                    <aside-item v-if="is_manager()" icon="User" title="兼职用户" index="PartTime"></aside-item>
                </aside-group>
                <aside-group title="素材库">
                    <aside-item icon="Files" title="全局图库" index="Index"></aside-item>
                    <aside-item icon="CollectionTag" title="素材标签" index="Tag" defaultCollapsed></aside-item>
                    <aside-item icon="Star" title="素材评分" index="Score"></aside-item>
                    <aside-item icon="Star" title="每日表现" index="Metric"></aside-item>
                </aside-group>
                <aside-group title="图库页">
                    <aside-item icon="Reading" title="分类管理" index="Category"></aside-item>
                    <aside-item icon="Aim" title="新手引导图" index="Rookie"></aside-item>
                    <aside-item icon="Timer" title="每日上新" index="Update"></aside-item>
                    <aside-item icon="Star" title="封面图" index="Cover"></aside-item>
                </aside-group>
                <aside-group title="发现页">
                  <aside-item icon="CollectionTag" title="合集" index="Collection"></aside-item>
                </aside-group>
                <aside-group title="Daily页">
                    <aside-item icon="Calendar" title="日历" index="Daily"></aside-item>
                    <aside-item icon="Calendar" title="挑战" index="DailyChallenge"></aside-item>
                </aside-group>
                <aside-group title="我的">
                    <aside-item icon="GoldMedal" title="成就" index="Achievement"></aside-item>
                </aside-group>
                <aside-group title="运营活动">
                  <aside-item icon="Monitor" title="弹窗" index="Popup"></aside-item>
                </aside-group>
                <aside-group title="内购">
                  <aside-item index="Subscription" title="订阅活动" icon="Goods"></aside-item>
                  <aside-item index="NewbieSales" title="新用户活动" icon="Goods"></aside-item>
                </aside-group>

            </aside-nav>
            <el-main :style="{padding: $route.name === 'Tag'?'0':'var(--el-main-padding)'}">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import {useDark, useToggle} from '@vueuse/core';
import AsideNav from "./base/AsideNav.vue";
import AsideGroup from "./base/AsideGroup.vue";
import AsideItem from "./base/AsideItem.vue";
import axios from "ts-axios-new";
import {Sunny, Moon} from '@element-plus/icons-vue'
import {is_manager} from "./libs/permission";

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches, Sunny, Moon,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight - 64,
            media: window.matchMedia('(prefers-color-scheme: dark)'),
            country_list: [], cdn_url: import.meta.env.VITE_CDN_URL,
            user: {
                user_id: '',
                avatar: '',
                name: '',
                email: '',
                access: [],
            },
            part_time_mode: false,
        }
    },
    methods: {
        is_manager,

        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        this.changeDark();
        axios.get('https://console.plutodesk.com/api/v1/country').then(res => {
            this.country_list = res.data.data.countryList;
        });
    },
}
</script>
<style>
html, body {
    margin: 0;
    padding: 0;
}

.el-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}

.dark-switch i {
    color: var(--el-text-color-primary) !important;
}
</style>
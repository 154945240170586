<template>
    <el-dialog v-model="dialog_opened" width="90%" :before-close="close">
        <el-row v-loading="loading">
            <el-col :span="11">
                <div class="canvas" id="canvas">
<!--                    <img :src="`${$root.cdn_url}/${image.origin_fore}`" style="width: 100%" v-if="image" id="fore">-->
                    <img :src="`${$root.cdn_url}/${image.type===3?image.origin_back:image.origin_fore}`"
                         style="width: 100%;" v-if="image" id="fore" :style="{opacity: image.type===3?0.1:1}">
                    <canvas ref="canvas" width="2048" height="2048" class="layer" v-loading="loading"></canvas>
                    <img id="area" class="layer">
                </div>
            </el-col>
            <el-col :span="2" style="text-align: center;">
                <el-divider direction="vertical" style="height: 100%"></el-divider>
            </el-col>
            <el-col :span="11" v-if="data">
                <el-switch v-model="small" :inactive-value="false" :active-value="true" inactive-text="不显示"
                           active-text="显示小色块" @change="changeSmall"></el-switch>
                <p>共 {{ data.colorGroup.length }} 种颜色；共 {{ Object.keys(data.cells).length }} 个色块。</p>
                <div>
                    <div v-for="g in data.colorGroup" class="circle" @click="fillColor(g)" :class="g.active?'active':''"
                         :style="{backgroundColor: g.color, color: calColor(g.color)}">
                        {{ g.regions.length }}
                    </div>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-button type="primary" @click="close">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import animationData from "../assets/marker.json";
import lottie from "lottie-web";

export default {
    name: "Skeleton",
    data() {
        return {
            loading: false, dialog_opened: false, mask: null, small: false, image: null, data: null, worker: null,
        }
    },
    methods: {
        init(image) {
            this.dialog_opened = this.loading = true;
            this.image = image;
            axios.get(`/cms/v1/test/image/${image.id}/map`).then(res => {
                  this.data = res.data.data.data;
                  this.data.colorGroup.forEach(g => {
                      g.active = false;
                  });
                  const canvas = this.$refs.canvas;
                  const ctx = canvas.getContext('2d');
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  const image = new Image();
                  image.src = `data:image/webp;base64,${res.data.data.mask}`;
                  image.onload = _ => {
                      this.mask = document.createElement('canvas');
                      const ctx = this.mask.getContext('2d');
                      this.mask.width = image.width;
                      this.mask.height = image.height;
                      ctx.drawImage(image, 0, 0);
                  }
                  this.loading = false;
                  // this.draw();
              });
        },
        close() {
            this.cleanMarker();
            this.dialog_opened = this.loading = this.canvas_loading = this.small = false;
            this.$nextTick(_ => {
                this.image = this.mask = this.data = null;
                this.zoom = 1;
            });
        },
        calColor(color) {
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return r * 0.299 + g * 0.587 + b * 0.144 >= 128 ? '#606266' : '#EBEEF5';
        },
        drawCircle(cell) {
            const canvas = this.$refs.canvas;
            const fore = document.getElementById('fore');
            const width = fore.parentElement.clientWidth;
            const height = fore.parentElement.clientHeight;
            const marker = document.createElement('div');
            marker.className = 'marker mask-marker';
            marker.style.position = 'absolute';
            marker.style.zIndex = '999';
            const x = cell.textPos[0] + cell.topLeft[0];
            const y = cell.textPos[1] + cell.topLeft[1];

            const canvas_width = fore.naturalWidth;
            const canvas_height = fore.naturalHeight;
            marker.style.left = (x / canvas_width * width - 50) + 'px';
            marker.style.top = (y / canvas_height * height - 50) + 'px';
            marker.style.width = '100px';
            lottie.loadAnimation({
                container: marker,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData,
            });
            canvas.insertAdjacentElement('afterend', marker);
        },
        cleanMarker() {
            document.querySelectorAll('.mask-marker').forEach(e => {
                e.parentElement.removeChild(e);
            });
        },
        changeSmall() {
            this.cleanMarker();
            this.data.colorGroup.forEach(g => {
                g.active = false;
            });
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (this.small) {
                const ctx1 = this.mask.getContext('2d');
                const map_data = ctx1.getImageData(0, 0, canvas.width, canvas.height).data;
                const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                Object.keys(this.data.cells).forEach(k => {
                    const cell = this.data.cells[k];
                    if (cell.textSize < 15 /* && cell.width < 50 && cell.height < 50 */) {
                        this.drawCircle(cell);
                        for (let i = 0; i < data.data.length; i += 4) {
                            if (map_data[i] === (k >> 16) % 256 && map_data[i + 1] === (k >> 8) % 256 && map_data[i + 2] === k % 256) {
                                data.data[i] = cell.avgColor[0];
                                data.data[i + 1] = cell.avgColor[1];
                                data.data[i + 2] = cell.avgColor[2];
                                data.data[i + 3] = 255;
                            }
                        }
                    }
                });
                ctx.putImageData(data, 0, 0);
            }
        },
        fillColor(group) {
            this.data.colorGroup.forEach(g => {
                g.active = false;
            });
            this.cleanMarker();
            group.active = true;
            if (this.small) {
                this.small = false;
                this.changeSmall();
            }
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const ctx1 = this.mask.getContext('2d');
            const map_data = ctx1.getImageData(0, 0, canvas.width, canvas.height).data;
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);

            group.regions.forEach(r => {
                for (let i = 0; i < data.data.length; i += 4) {
                    if (map_data[i] === (r >> 16) % 256 && map_data[i + 1] === (r >> 8) % 256 && map_data[i + 2] === r % 256) {
                        data.data[i] = parseInt(group.color.slice(1, 3), 16);
                        data.data[i + 1] = parseInt(group.color.slice(3, 5), 16);
                        data.data[i + 2] = parseInt(group.color.slice(5, 7), 16);
                        data.data[i + 3] = 255;
                    }
                }
                const cell = this.data.cells[r + ''];
                if (cell.textSize < 20 && cell.width < 50 && cell.height < 50) {
                    this.drawCircle(cell);
                }
            });

            ctx.putImageData(data, 0, 0);
        }
    },
}
</script>

<style scoped>
.canvas {
    position: relative;
    background-color: #FFF;
    overflow: hidden;
}

.layer {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.circle {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    display: inline-block;
}

.circle.active {
    font-size: 18px;
    margin: 0;
    border: 5px solid var(--el-color-primary);
}
</style>
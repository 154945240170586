<template>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="120px" :model="form">
            <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请选择类型'}]">
                <el-select v-model="form.type" :disabled="loading">
                    <el-option v-for="(v, k) in achievement_type_enum" :label="v" :value="k"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分类" prop="category" :rules="[{required: true, message: '请选择分类'}]"
                          v-if="form.type === 'type_category_'">
                <el-select v-model="form.category" :disabled="loading" filterable>
                    <el-option label="Daily" value="DAILY"></el-option>
                    <el-option v-for="c in category_list" :label="c.i18n_name.EN" :value="c.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="奖牌图片" prop="medal" :rules="[{required: true, message: '请上传奖牌'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleUpload"
                           accept="image/*" :data="{prefix: 'medal'}" :onProgress="handleProgress">
                    <img v-if="form.medal" :src="`${$root.cdn_url}/${form.medal}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {update} from "../../libs/utils";
import {initI18n} from "../../libs/init";
import axios from "ts-axios-new";

export default {
    name: "AchievementDialog",
    inject: ['category_list', 'achievement_type_enum'],
    data() {
        return {
            dialog_opened: false, loading: false, editing: null, group: null,
            form: {
                group_id: '',
                type: '',
                category: '',
                medal: '',
                i18n_name: initI18n(),
                i18n_desc: initI18n(),
                active: false,
            },
        }
    },
    methods: {
        init(group, item) {
            this.group = group;
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.form.group_id = group.id;
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = this.group = null;
            this.form.i18n_name = initI18n();
            this.form.i18n_desc = initI18n();
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/achievement/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.$emit('updated', res.data.data);
                            this.reset();
                        });
                    } else {
                        axios.post(`/cms/v1/achievement`, this.form).then(res => {
                            this.group.achievements.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        handleUpload(res) {
            this.loading = false;
            this.form.medal = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
    }
}
</script>

<style scoped>

</style>
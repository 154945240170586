<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" width="100" prop="id"></el-table-column>
        <el-table-column label="名称" width="100" prop="i18n_name.EN"></el-table-column>
        <el-table-column label="封面" width="200" prop="resource">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.resource}`" lazy
                          style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="价格" width="100" prop="cost"></el-table-column>
        <el-table-column label="上线时间" width="150" prop="online_date"></el-table-column>
        <el-table-column label="签到解锁" width="150" prop="checkin_unlock">
          <template #default="scope">
            <el-tag v-if="scope.row.checkin_unlock">Yes</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column label="第几天可见" width="150" prop="day">-->
<!--              <template #default="scope">-->
<!--                <el-select style="width: 100px;" v-model="scope.row.day" :disabled="loading" @change="update(scope.row)">-->
<!--                  <el-option v-for="day in 100" :label="day-1" :value="parseInt(day)-1"></el-option>-->
<!--                </el-select>-->
<!--              </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" width="300">
              <template #default="scope">
                  <el-button type="success" circle icon="Top" :loading="loading" size="small"
                             :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                  <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                             :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                  <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                  <el-button type="success" size="small" :disabled="loading" @click="openCollection(scope.row)">内容
                  </el-button>
                  <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
              </template>
          </el-table-column>
    </el-table>
    <collection-image ref="collection"></collection-image>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
<!--            <el-form-item label="主键" prop="key" :rules="[{required: true, message: '请输入主键'}]">-->
<!--                <el-input v-model="form.key" :disabled="loading || editing"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
              <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
<!--            <el-form-item label="简体中文" prop="i18n_name.ZH_HANS" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.ZH_HANS" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="繁体中文" prop="i18n_name.ZH_HANT" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.ZH_HANT" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="英语" prop="i18n_name.EN" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.EN" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="法语" prop="i18n_name.FR" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.FR" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="印地语" prop="i18n_name.HI" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.HI" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="意大利语" prop="i18n_name.IT" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.IT" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="阿拉伯语" prop="i18n_name.AR" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.AR" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="西班牙语" prop="i18n_name.ES" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.ES" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="葡萄牙语" prop="i18n_name.PT" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.PT" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="德语" prop="i18n_name.DE" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.DE" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="希腊语" prop="i18n_name.EL" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.EL" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="印尼语" prop="i18n_name.ID" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.ID" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="韩语" prop="i18n_name.KO" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.KO" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="泰语" prop="i18n_name.TH" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.TH" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="荷兰语" prop="i18n_name.NL" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.NL" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="土耳其语" prop="i18n_name.TR" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.TR" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="波兰语" prop="i18n_name.PL" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.PL" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="越南语" prop="i18n_name.VI" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.VI" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="芬兰语" prop="i18n_name.FI" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.FI" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="俄语" prop="i18n_name.RU" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.RU" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="匈牙利语" prop="i18n_name.HU" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.HU" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="日语" prop="i18n_name.JA" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.JA" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="挪威语" prop="i18n_name.NO" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.NO" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="瑞典语" prop="i18n_name.SV" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.SV" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="乌克兰语" prop="i18n_name.UK" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.UK" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="马来语" prop="i18n_name.MS" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.MS" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="希伯来语" prop="i18n_name.IW" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.IW" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="罗马尼亚语" prop="i18n_name.RO" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.RO" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="塔加洛语" prop="i18n_name.TL" :rules="[{required: true, message: '请输入名称'}]">-->
<!--                <el-input v-model="form.i18n_name.TL" :disabled="loading"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="横向封面" prop="resource" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress">
                    <img v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
<!--            <el-form-item label="竖向封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">-->
<!--                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleCoverSuccess"-->
<!--                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress">-->
<!--                    <img v-if="form.cover" :src="`${$root.cdn_url}/${form.cover}`"-->
<!--                         style="width: 200px">-->
<!--                    <el-button size="small" v-else :disabled="loading">上传</el-button>-->
<!--                </el-upload>-->
<!--            </el-form-item>-->
            <el-form-item label="价格" prop="cost" :rules="[{required: true, message: '请输入金额'}]">
                <el-input-number v-model="form.cost" :disabled="loading"></el-input-number>
            </el-form-item>
<!--            <el-form-item label="新用户可见" prop="day" :rules="[{required: false, message: '新用户第几天可见'}]">-->
<!--              <el-select style="width: 100px;" v-model="form.day" :disabled="loading">-->
<!--                <el-option v-for="day in 100" :label="day-1" :value="parseInt(day)-1"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item label="上线日期" prop="online_date" :rules="[{required: true, message: '请选择上线时间'}]">
                <el-date-picker v-model="form.online_date" :disabled="loading" :clearable="false"
                                value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item label="签到解锁" prop="checkin_unlock">
              <el-checkbox v-model="form.checkin_unlock" :disabled="loading">签到解锁</el-checkbox>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import CollectionImage from "./CollectionImage";
import {update} from "../libs/utils";
import {ElMessageBox} from 'element-plus'
import {initI18n, initI18n_required} from "../libs/init";

export default {
    name: "Collection",
    components: {CollectionImage},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                key: '',
                cost: 200,
                i18n_name: initI18n_required(),
                resource: '',
                // cover: null,
                online_date: null,
                // day: null
                checkin_unlock: false,
            },
        }
    },
    computed: {
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/collection`).then(res => {
                this.data = res.data.data.collectionList;
                this.loading = false;
            })
        },
        openCollection(collection) {
            this.$refs.collection.init(collection);
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        update(row) {
          // this.loading = true;
          axios.put(`/cms/v1/collection/${row.id}`, row).then(_ => {
              // this.loading = false;
          })
        },
        handleSuccess(res) {
            this.form.resource = res.data.name;
            this.loading = false;
        },
        // handleCoverSuccess(res) {
        //     this.form.cover = res.data.name;
        //     this.loading = false;
        // },
        handleProgress() {
            this.loading = true;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/collection/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/collection`, this.form).then(res => {
                            this.data.unshift(res.data.data)
                            this.reset();
                        })
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/collection`, {sort}).then(_ => {
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: "warning",
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/collection/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {})
        },
    },
    mounted() {
       this.init();
    },
}
</script>

<style scoped>

</style>
export function initI18n() {
    return {
        EN: '',
        FR: '',
        HI: '',
        IT: '',
        AR: '',
        ES: '',
        PT: '',
        DE: '',
        EL: '',
        ZH_HANS: '',
        ZH_HANT: '',
        ID: '',
        KO: '',
        TH: '',
        NL: '',
        TR: '',
        PL: '',
        VI: '',
        FI: '',
        RU: '',
        HU: '',
        JA: '',
        NO: '',
        SV: '',
        UK: '',
        MS: '',
        IW: '',
        RO: '',
        TL: '',
    }
}

export function initI18n_required() {
    return {
        EN: '',
        FR: '',
        IT: '',
        ES: '',
        PT: '',
        DE: '',
        ZH_HANS: '',
        ZH_HANT: '',
        KO: '',
        NL: '',
        PL: '',
        RU: '',
        JA: '',
        NO: '',
        MS: '',
        AR: '',
    }
}
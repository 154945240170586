<template>
    <div style="display: flex">
        <el-select v-model="form.country" style="width: 100px" @change="init" filterable
                   :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'">
                {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
        </el-select>
        <el-select style="width: 100px" placeholder="分类" filterable v-model="form.category_id"
                   :disabled="loading" clearable>
            <el-option v-for="c in category_list" :value="c.id" :label="c.i18n_name.ZH_HANS"></el-option>
        </el-select>
        <el-input style="width: 100px" placeholder="ID" v-model="form.id"
                  :disabled="loading"></el-input>
        <el-select style="width: 100px" placeholder="作者" filterable v-model="form.author"
                   :disabled="loading" clearable>
            <el-option v-for="c in author_list" :value="c"></el-option>
        </el-select>
        <el-select v-model="form.type" placeholder="类型" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="form.module" placeholder="模块" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="form.rookie" placeholder="新手图" clearable style="width: 100px" :disabled="loading">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <date-range-picker v-model="form" :disabled="loading" clearable start-placeholder="上线开始时间"
                           end-placeholder="上线结束时间"></date-range-picker>
    </div>
    <downloadable-table :data="dataPage" v-loading="loading" style="margin-top: 20px" @sort-change="sortChange"
                        :summaryMethod="summaryMethod" show-summary title="素材评分" :complete="dataFilter">
        <el-table-column label="假ID" width="60" prop="nickname"></el-table-column>
        <el-table-column label="预览图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.cdn_url}/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="完成图" width="120" download-hide>
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.cdn_url}/${scope.row.finished}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="分类" width="80" prop="category" sortable>
            <template #default="scope">
                <template v-for="c in scope.row.categories">
                    <template v-for="cate in category_list">
                        <el-tag v-if="c === cate.id">{{ cate.i18n_name.ZH_HANS }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="作者" width="100" prop="authors"></el-table-column>
        <el-table-column label="上线时间" width="140" prop="create_date" sortable></el-table-column>
        <el-table-column label="难度" width="100" prop="difficulty">
            <template #default="scope">
                <div v-if="scope.row.cells" :class="scope.row.difficulty?'color-error':''">
                    L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                    ({{ scope.row.cells }})
                </div>
            </template>
        </el-table-column>
        <el-table-column label="3日价值">
            <el-table-column label="展示" prop="impression_3" width="100"></el-table-column>
            <el-table-column label="进入" prop="enter_3" width="100"></el-table-column>
            <el-table-column label="进入率" prop="enter_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.enter_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="完成率" prop="finish_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.finish_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="分享率" prop="share_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.share_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="人均hint" prop="hint_3" width="100">
                <template #default="scope">
                    {{ (scope.row.hint_3 / scope.row.enter_3).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column label="人均完成" prop="colored_3" width="100">
                <template #default="scope">
                    {{ (scope.row.colored_3 / scope.row.enter_3 / scope.row.cells * 100).toFixed(2) }}%
                </template>
            </el-table-column>
            <el-table-column label="人均耗时" prop="duration_3" width="100">
                <template #default="scope">
                    {{ (scope.row.duration_3 / scope.row.enter_3).toFixed(2) }}秒
                </template>
            </el-table-column>
            <el-table-column label="人均耗时(New)" prop="duration_3" width="150">
              <template #default="scope">
                {{ scope.row.exit_3 === 0 ? '' : (scope.row.duration_3 / scope.row.exit_3).toFixed(2) + '秒' }}
<!--                {{ (scope.row.duration_3 / scope.row.exit_3).toFixed(2) }}秒-->
              </template>
            </el-table-column>
            <el-table-column label="综合得分" prop="score_3" width="100">
                <template #default="scope">
                    {{ (scope.row.duration_3 / scope.row.impression_3).toFixed(2) }}
                </template>
            </el-table-column>
        </el-table-column>
    </downloadable-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import DownloadableTable from "../base/DownloadableTable";

export default {
    name: "Score",
    components: {DownloadableTable, DateRangePicker},
    data() {
        return {
            loading: false, category_list: [], author_list: [], data: [], page: 1, size: 20, image_list: [],
            daily: [], rookie: [],
            image_module_enum: {1: '图库', 2: 'DAILY', 3: 'Bonus'},
            image_type_enum: {1: '单色', 2: '彩绘'},
            form: {
                country: null,
                category_id: null,
                id: null,
                author: null,
                type: null,
                start: null,
                end: null,
                module: null,
                rookie: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/metric`, {params: {country: this.form.country}}).then(res => {
                    this.data = res.data.data.metricList;
                }),
            ]).then(_ => {
                const image_map = {};
                this.image_list.forEach(m => {
                    image_map[m.id] = m;
                });
                this.data.forEach(d => {
                    if (image_map[d.image_id]) {
                        d.type = image_map[d.image_id].type;
                        d.categories = image_map[d.image_id].categories;
                        d.online_date = image_map[d.image_id].online_date;
                        d.cells = image_map[d.image_id].cells;
                        d.authors = image_map[d.image_id].authors;
                        d.thumbnail = image_map[d.image_id].thumbnail;
                        d.finished = image_map[d.image_id].finished;
                        d.nickname = image_map[d.image_id].nickname;
                        d.module = image_map[d.image_id].module;
                    }
                    d.enter_rate_3 = d.impression_3 ? parseInt(d.enter_3 / d.impression_3 * 10000) / 100.0 : null;
                    d.finish_rate_3 = parseInt(d.finish_3 / d.enter_3 * 10000) / 100.0 || 0;
                    d.save_rate_3 = parseInt(d.save_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.share_rate_3 = parseInt(d.share_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.enter_rate_7 = d.impression_7 ? parseInt(d.enter_7 / d.impression_7 * 10000) / 100.0 : null;
                    d.finish_rate_7 = parseInt(d.finish_7 / d.enter_7 * 10000) / 100.0 || 0;
                    d.save_rate_7 = parseInt(d.save_7 / d.finish_7 * 10000) / 100.0 || 0;
                    d.share_rate_7 = parseInt(d.share_7 / d.finish_7 * 10000) / 100.0 || 0;
                });
                this.sortChange({});
                this.loading = false;
            })
        },
        resort() {
            this.data.forEach(d => {
                d.score = this.form.enter * d.enter_7 + this.form.finish * d.finish_7 / d.enter_7 + this.form.save * d.save_7 / d.finish_7 + this.form.share * d.share_7 / d.finish_7;
            });
            this.data.sort(function (a, b) {
                return b.score - a.score;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
        summaryMethod({columns, data}) {
            const summary = [];
            let enter = 0, impression = 0, finish = 0, share = 0, hint = 0, colored = 0, cells = 0, duration = 0, t = 0;
            this.dataFilter.forEach(d => {
                enter += d.enter_3;
                impression += d.impression_3;
                finish += d.finish_3;
                share += d.share_3;
                hint += d.hint_3;
                colored += d.colored_3;
                cells += d.cells;
                duration += d.duration_3;
                t += 1;
            });
            columns.forEach((c, i) => {
                if (c.property === 'enter_3') {
                    summary[i] = Math.round(enter / t);
                } else if (c.property === 'impression_3') {
                    summary[i] = Math.round(impression / t);
                } else if (c.property === 'enter_rate_3') {
                    summary[i] = parseInt(enter / impression * 10000) / 100 + '%';
                } else if (c.property === 'finish_rate_3') {
                    summary[i] = parseInt(finish / enter * 10000) / 100 + '%';
                } else if (c.property === 'share_rate_3') {
                    summary[i] = parseInt(share / finish * 10000) / 100 + '%';
                } else if (c.property === 'hint_3') {
                    summary[i] = (hint / enter).toFixed(2);
                } else if (c.property === 'colored_3') {
                    summary[i] = (colored / enter / cells * t * 100).toFixed(2) + '%';
                } else if (c.property === 'duration_3') {
                    summary[i] = (duration / enter).toFixed(2) + '秒';
                } else if (c.property === 'score_3') {
                    summary[i] = (duration / impression).toFixed(2);
                }
            });
            return summary;
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/image`).then(res => {
                this.image_list = res.data.data.imageList;
                this.image_list.forEach(i => {
                    i.authors.forEach(a => {
                        if (!this.author_list.includes(a)) {
                            this.author_list.push(a);
                        }
                    })
                })
            }),
            axios.get('/cms/v1/category').then(res => {
                this.category_list = res.data.data.categoryList;
            }),
            axios.get('/cms/v1/rookie').then(res => {
                this.rookie = res.data.data.imageList;
            }),
            axios.get('/cms/v1/daily').then(res => {
                this.daily = res.data.data.imageList;
            }),
        ]).then(_ => {
            const daily = {}
            this.daily.forEach(d => {
                daily[d.image_id] = d.date;
            });
            this.image_list.forEach(i => {
                if (daily[i.id]) {
                    i.online_date = daily[i.id];
                }
            });
            this.init();
        })
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = d.impression_3 > 0;
                valid &= !this.form.id || (d.nickname + '').includes(this.form.id);
                valid &= !this.form.author || d.authors && d.authors.includes(this.form.author);
                valid &= !this.form.type || d.type === this.form.type;
                valid &= !this.form.category_id || d.categories && d.categories.includes(this.form.category_id);
                valid &= !this.form.start && !this.form.end || d.online_date >= this.form.start && d.online_date <= this.form.end;
                valid &= !this.form.module || d.module === this.form.module;
                if (valid) {
                    if (this.form.rookie === true) {
                        valid = false;
                        this.rookie.forEach(r => {
                            if (r.image_id === d.image_id) {
                                valid = true;
                            }
                        })
                    } else if (this.form.rookie === false) {
                        this.rookie.forEach(r => {
                            if (r.image_id === d.image_id) {
                                valid = false;
                            }
                        })
                    }
                }
                return valid;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

</style>
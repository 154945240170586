<template>
    <el-dialog v-model="dialog_opened" :bebore-close="close">
        <div ref="json" v-loading="loading" style="height: 500px"></div>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="close">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "AchievementExport",
    data() {
        return {
            loading: false, dialog_opened: false,
        }
    },
    methods: {
        init() {
            this.dialog_opened = this.loading = true;
            axios.get('/cms/v1/client/achievement').then(res => {
                this.$refs.json.childNodes.forEach(node => {
                    this.$refs.json.removeChild(node);
                });
                this.editor = new JSONEditor(this.$refs.json, {
                    mode: 'code',
                }, res.data.data);
                this.loading = false;
            })
        },
        close() {
            this.loading = this.dialog_opened = false;
        }
    }
}
</script>

<style scoped>

</style>
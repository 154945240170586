<template>
    <el-dialog v-model="dialog_opened" width="90%" :before-close="close" :close-on-click-modal="false">
        <el-row>
            <el-col :span="16" style="justify-content: center;display: flex">
                <div v-loading="loading" v-if="image" class="pre-canvas" id="pre-canvas">
                    <div id="elastic" :style="{transform: `scale(${scale})translate(${x}px, ${y}px)`}">
                        <canvas ref="canvas" style="width: 100%;"></canvas>
                        <img id="svg">
                        <canvas ref="line" id="line"></canvas>
                        <canvas ref="cover" id="cover"></canvas>
                    </div>
                </div>
                <div style="margin-left: 10px;display: flex; flex-direction: column;">
                    <div>
                        <el-button size="small" icon="Plus" @click="zoomIn" :disabled="scale === 5"></el-button>
                    </div>
                    <div>
                        <el-button size="small" icon="Minus" @click="zoomOut" :disabled="scale === 1"></el-button>
                    </div>
                    <div>
                        <el-button size="small" icon="FullScreen" @click="reset"></el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div>
                    <el-radio-group v-model="positive">
                        <el-radio :label="true">上色</el-radio>
                        <el-radio :label="false">清除单色</el-radio>
                    </el-radio-group>
                </div>
                <div>
                    <el-button type="danger" :disabled="loading" @click="cleanAll">清除所有颜色</el-button>
                </div>
                <div>
                    <el-radio-group v-model="lock">
                        <el-radio :label="true">锁定其它区域</el-radio>
                        <el-radio :label="false">不锁定</el-radio>
                    </el-radio-group>
                </div>
                <div style="display: flex;flex-wrap: wrap;">
                    <div class="circle" v-for="(t, i) in colors" :style="{backgroundColor: `rgb(${t}, ${t}, ${t})`}"
                         @click="changeActive(i)"
                         :class="(i >= colors.length / 2 ? 'dark': 'light') + (active === i? ' active': '')">
                        {{ stats[i] }}
                    </div>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-button text type="primary" @click="close" :loading="loading">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading" :disabled="image && image.status===4">确定
            </el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import workerCode from './worker.js?raw'
import {ElMessageBox} from 'element-plus'

export default {
    name: "Mask",
    data() {
        const colors = new Array(100);
        for (let i = 0; i < colors.length; i++) {
            colors[i] = Math.round((colors.length - i) * 2.5 + 3)
        }
        return {
            loading: false, dialog_opened: false, data: null, worker: null, image: null, dragging: false,
            active: null, colors, stats: new Array(colors.length).fill(0), lock: false, positive: true,
            scale: 1, x: 0, y: 0, _x: 0, _y: 0, last_x: 0, last_y: 0, width: 0, height: 0, map_data: null,
        }
    },
    methods: {
        init(image) {
            this.dialog_opened = this.loading = true;
            this.image = image;
            axios.get(`/cms/v1/test/image/${image.id}/mask`).then(res => {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const line = this.$refs.line;
                const ctx_line = line.getContext('2d');
                ctx_line.clearRect(0, 0, line.width, line.height);
                const image = new Image();
                image.src = `data:image/webp;base64,${res.data.data.map}`;
                image.onload = _ => {
                    const pre = document.getElementById('pre-canvas');
                    this.height = this.$root.height + 64;
                    this.width = this.height / image.height * image.width;
                    pre.style.height = this.height + 'px';
                    pre.style.width = this.width + 'px';

                    const cover = this.$refs.cover;
                    const map = document.createElement('canvas');
                    const ctx1 = map.getContext('2d');
                    map.width = canvas.width = line.width = cover.width = image.width;
                    map.height = canvas.height = line.height = cover.height = image.height;
                    ctx1.drawImage(image, 0, 0);

                    const map_data = ctx1.getImageData(0, 0, image.width, image.height);
                    this.map_data = map_data;
                    const line_data = ctx_line.getImageData(0, 0, line.width, line.height);

                    const local = localStorage.getItem(this.image.id);
                    if (res.data.data.mask || local) {
                        const mask = new Image();
                        if (local) {
                            mask.src = local;
                        } else {
                            mask.src = `data:image/png;base64,${res.data.data.mask}`;
                        }
                        mask.onload = _ => {
                            ctx.drawImage(mask, 0, 0);
                            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            const exists = {};
                            for (let i = 0; i < data.data.length; i += 4) {
                                if (data.data[i] === 0)
                                    continue
                                const idx = map_data.data[i] * 255 * 255 + map_data.data[i + 1] * 255 + map_data.data[i + 2];
                                if (exists[idx])
                                    continue
                                exists[idx] = data.data[i];
                                this.stats[this.colors.indexOf(data.data[i])]++;
                            }
                            this.worker.postMessage({map: map_data, data, line_data});
                        }
                    } else {
                        this.worker.postMessage({
                            map: map_data, line_data,
                            data: ctx.getImageData(0, 0, image.width, image.height),
                        });
                    }

                    canvas.addEventListener('mousedown', this.startDrag);
                    canvas.addEventListener('mousemove', this.drag);
                    canvas.addEventListener('mouseup', this.stopDrag);
                    canvas.addEventListener('mouseleave', this.stopDrag);
                    canvas.addEventListener('click', this.click);
                    canvas.addEventListener('wheel', this.wheel);
                }
                const svg = document.getElementById('svg');
                if (this.image.origin_mask) {
                    svg.src = `data:image/webp;base64,${res.data.data.svg}`;
                } else {
                    svg.src = `data:image/svg+xml;base64,${res.data.data.svg}`;
                }
                this.loading = false;
            });
        },
        wheel(e) {
            if (e.metaKey || e.ctrlKey) {
                e.preventDefault();
                if (e.deltaY < 0) {
                    if (this.scale < 5) {
                        this.scale -= e.deltaY / 100;
                    }
                    if (this.scale > 5) {
                        this.scale = 5;
                    }
                } else if (e.deltaY > 0) {
                    if (this.scale > 1) {
                        this.scale -= e.deltaY / 100;
                    }
                    if (this.scale < 1) {
                        this.scale = 1;
                    }
                }
            }
        },
        click(e) {
            if (!e.metaKey && !e.ctrlKey) {
                if (this.positive && this.active === null) {
                    const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d');
                    const rect = canvas.getBoundingClientRect();
                    const offset_x = rect.left;
                    const offset_y = rect.top;
                    const x = Math.round((e.clientX - offset_x) / this.scale * canvas.width / canvas.clientWidth);
                    const y = Math.round((e.clientY - offset_y) / this.scale * canvas.height / canvas.clientHeight);
                    const c = ctx.getImageData(x, y, 1, 1).data[0];
                    if (c)
                        this.active = this.colors.indexOf(c);
                } else if (!this.positive) {
                    const canvas = this.$refs.canvas;
                    const rect = canvas.getBoundingClientRect();
                    const offset_x = rect.left;
                    const offset_y = rect.top;
                    const x = Math.round((e.clientX - offset_x) / this.scale * canvas.width / canvas.clientWidth);
                    const y = Math.round((e.clientY - offset_y) / this.scale * canvas.height / canvas.clientHeight);
                    this.clean(x, y);
                }
            }
        },
        drag(e) {
            if (this.dragging && (e.metaKey || e.ctrlKey)) {
                const deltaX = e.clientX - this._x;
                const deltaY = e.clientY - this._y;
                this.x = deltaX / this.scale + this.last_x;
                this.y = deltaY / this.scale + this.last_y;
                const maxX = (this.scale - 1) * this.width / 2 / this.scale;
                const maxY = (this.scale - 1) * this.height / 2 / this.scale;
                if (this.x > maxX) {
                    this.x = maxX;
                }
                if (this.y > maxY) {
                    this.y = maxY;
                }
                if (this.x < -maxX) {
                    this.x = -maxX
                }
                if (this.y < -maxY) {
                    this.y = -maxY
                }
            } else if (this.dragging && this.active !== null && this.positive) {
                const canvas = this.$refs.canvas;
                const rect = canvas.getBoundingClientRect();
                const offset_x = rect.left;
                const offset_y = rect.top;
                const x = (e.clientX - offset_x) / this.scale * canvas.width / canvas.clientWidth;
                const y = (e.clientY - offset_y) / this.scale * canvas.height / canvas.clientHeight;
                this.draw(parseInt(x + 0.5) + 1, parseInt(y + 0.5) + 1);
            }
        },
        clean(x, y) {
            this.worker.postMessage({
                x, y, action: 'clean',
            });
        },
        draw(x, y) {
            this.worker.postMessage({
                x, y, to: this.colors[this.active], lock: this.lock,
            });
        },
        handleWorkerMessage(event) {
            if (event.data.data) {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                ctx.putImageData(event.data.data, 0, 0);
            }
            if (event.data.to) {
                this.stats[this.colors.indexOf(event.data.to)]++;
            }
            if (event.data.from) {
                if (event.data.action === 'clean') {
                    this.stats[this.colors.indexOf(event.data.from)] = 0;
                } else {
                    this.stats[this.colors.indexOf(event.data.from)]--;
                }
            }
            if (event.data.line_data) {
                const line = this.$refs.line;
                const line_ctx = line.getContext('2d');
                line_ctx.putImageData(event.data.line_data, 0, 0);
            }
        },
        startDrag(e) {
            this.dragging = true;
            if (e.ctrlKey || e.metaKey) {
                this._x = e.clientX;
                this._y = e.clientY;
                this.last_x = this.x;
                this.last_y = this.y;
            } else {
                this.drag(e);
            }
        },
        stopDrag() {
            if (this.dragging) {
                this.dragging = false;
                const line = this.$refs.line;
                const ctx_line = line.getContext('2d');
                const line_data = ctx_line.getImageData(0, 0, line.width, line.height);
                this.worker.postMessage({line_data, color: this.colors[this.active]});
                const canvas = this.$refs.canvas;
                const image_url = canvas.toDataURL();
                localStorage.setItem(this.image.id, image_url);
            }
        },
        close() {
            this.dialog_opened = this.loading = this.dragging = false;
            this.x = this.y = 0;
            this.scale = 1;
            this.positive = true;
            this.$nextTick(_ => {
                this.image = this.map_data = this.data = this.active = this.start = null;
                this.stats = new Array(this.colors.length).fill(0);
            });
            localStorage.removeItem(this.image.id);
        },
        changeActive(num) {
            const line = this.$refs.line;
            const ctx_line = line.getContext('2d');
            const line_data = ctx_line.getImageData(0, 0, line.width, line.height);
            if (this.active === num) {
                this.active = null;
            } else {
                this.active = num;
                this.worker.postMessage({line_data, color: this.colors[this.active]});
            }
        },
        submit() {
            this.alert = [];
            this.active = null;
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < data.data.length; i += 4) {
                if (data.data[i + 3] === 0 && !(this.map_data.data[i] === 0 && this.map_data.data[i + 1] === 0 && this.map_data.data[i + 2] === 0)) {
                    this.alert.push(i);
                }
            }
            window.a = this.alert;
            if (this.alert.length > 0) {
                const line = this.$refs.line;
                const ctx_line = line.getContext('2d');
                const line_data = ctx_line.getImageData(0, 0, line.width, line.height);
                this.worker.postMessage({line_data, color: 0});
                return
            }
            this.loading = true;
            const form = new FormData();
            this.$refs.canvas.toBlob(blob => {
                form.append('file', blob, 'image.png')
                axios.post(`/cms/v1/test/image/${this.image.id}/mask`, form).then(res => {
                    this.close();
                });
            })
        },
        handleKeyDown(event) {
            if ((event.ctrlKey || event.metaKey) && event.keyCode === 90) {
                this.worker.postMessage({action: 'cancel'});
            }
        },
        cleanAll() {
            ElMessageBox.confirm('确定要清除所有颜色吗？', '提示', {}).then(_ => {
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const line = this.$refs.line;
                const ctx_line = line.getContext('2d');
                ctx_line.clearRect(0, 0, line.width, line.height);
                const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const line_data = ctx_line.getImageData(0, 0, line.width, line.height);
                for (let i = 0; i < this.stats.length; i++) {
                    this.stats[i] = 0;
                }
                this.worker.postMessage({action: 'cleanAll', data, line_data})
            }).catch(_ => {
            })
        },
        zoomIn() {
            if (this.scale < 5) {
                this.scale += 0.5;
            }
            if (this.scale > 5) {
                this.scale = 5;
            }
        },
        zoomOut() {
            if (this.scale > 1) {
                this.scale -= 0.5;
            }
            if (this.scale < 1) {
                this.scale = 1;
            }
        },
        reset() {
            this.scale = 1;
            this.x = this.y = 0;
        },
    },
    created() {
        const blob = new Blob([workerCode], {type: 'application/javascript'});
        const url = window.URL.createObjectURL(blob);
        this.worker = new Worker(url);
        this.worker.onmessage = this.handleWorkerMessage;
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        this.worker.terminate();
    }
}
</script>

<style scoped>
.pre-canvas {
    width: 100%;
    display: flex;
    background-color: var(--el-color-primary-light-5);
    overflow: hidden;
}

#elastic {
    position: relative;
}

#svg, #line, #cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}

.circle {
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    border: 1px solid var(--el-color-info);
}

.circle.light {
    color: #606266;
}

.circle.dark {
    color: #EBEEF5
}

.circle.active {
    font-size: 18px;
    margin: 0;
    border: 5px solid var(--el-color-primary);
}
</style>
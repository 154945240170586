<template>
    <div style="display: inline-flex">
  <!--        <el-date-picker v-model="form.date" :clearable="false" :disabled="loading"-->
<!--                        value-format="YYYY-MM-DD"></el-date-picker>-->
<!--        <el-select v-model="form.platform" :disabled="loading" @change="init" placeholder="平台" filterable-->
<!--                   clearable style="width: 150px; margin-right: 3px">-->
<!--          <el-option v-for="c in ['android', 'ios']" :value="c" :label="c"></el-option>-->
<!--        </el-select>-->
        <date-range-picker v-model="form" :disabled="loading" clearable start-placeholder="上线开始时间"
                           end-placeholder="上线结束时间" style="width:350px;"></date-range-picker>
<!--        <el-select v-model="form.module" :disabled="loading" @change="init" placeholder="模块" filterable-->
<!--                   clearable style="width: 150px; margin-right: 3px">-->
<!--          <el-option v-for="(v, k) in image_module_map" :label="v" :value="k"></el-option>-->
<!--        </el-select>-->
        <el-select v-model="form.module" placeholder="模块" clearable style="width: 100px; margin-left: 5px;"
                   :disabled="loading" :selected-label="image_module_map[form.module]">
          <el-option v-for="(v, k) in image_module_map" :key="k" :label="v" :value="k"></el-option>
        </el-select>
        <el-select v-model="form.country" style="width: 200px; margin-left: 5px" filterable :disabled="loading" placeholder="全部国家" clearable>
<!--          <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"-->
<!--                     :label="c.en_name + ' (' + c.code + ')'"> {{ c.en_name + ' (' + c.code + ')' }}-->
<!--          </el-option>-->
          <el-option v-for="c in $root.country_list" :key="c.ip_name" :value="c.ip_name"
                     :label="c.en_name + ' (' + c.code + ')'"></el-option>
        </el-select>
        <el-input style="width: 200px;margin-left: 5px;margin-right: 5px" placeholder="ID" v-model="form.id"
                  :disabled="loading"></el-input>
        <el-button type="primary" :disabled="loading" @click="init">查询</el-button>
    </div>
    <el-table :data="dataPage" v-loading="loading" style="margin-top: 20px" @sort-change="sortChange">
        <el-table-column label="ID" width="100" prop="image_id"></el-table-column>
        <el-table-column label="nickname" width="100" prop="nickname"></el-table-column>
        <el-table-column label="预览图" width="120" download-hide>
          <template #default="scope">
            <el-image style="width: 100px" lazy
                      :src="`${$root.cdn_url}/${scope.row.thumbnail}`"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="完成图" width="120" download-hide>
          <template #default="scope">
            <el-image style="width: 100px" lazy
                      :src="`${$root.cdn_url}/${scope.row.finished}`"></el-image>
          </template>
        </el-table-column>
<!--        <el-table-column label="激励" width="80" prop="level">-->
<!--            <template #default="scope">-->
<!--                {{ scope.row.cost ? '是' : '否' }}-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column label="作者" width="100" prop="authors"></el-table-column>
        <el-table-column label="上线时间" width="140" prop="online_date" sortable></el-table-column>
        <el-table-column v-if="this.form.module==='rookie'" label="第几天" width="100" prop="day" sortable></el-table-column>
        <el-table-column label="展示" prop="impression" width="100" sortable></el-table-column>
        <el-table-column label="进入" prop="enter" width="100" sortable></el-table-column>
        <el-table-column label="进入率" prop="enter_rate" width="100" sortable></el-table-column>
        <el-table-column label="完成" prop="finish" width="100" sortable></el-table-column>
        <el-table-column label="完成率" prop="finish_rate" width="100" sortable></el-table-column>
        <el-table-column label="hint" prop="hint" width="100" sortable></el-table-column>
        <el-table-column label="完成比例" prop="colored" width="100">
          <template #default="scope">
            {{ (scope.row.colored / scope.row.cells * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column label="平均时长" prop="duration" width="100"></el-table-column>
        <el-table-column label="分享" prop="share" width="100" sortable></el-table-column>
        <el-table-column label="分享率" prop="share_rate" width="100" sortable></el-table-column>
    </el-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import {timestampToDate} from "../libs/utils";
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker.vue";

export default {
    name: "Metric",
  components: {DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], page: 1, size: 20, image_list: [],
            image_module_map: {"library": '图库', "rookie": '新手图'},
            form: {
                  id: '',
                  // date: timestampToDate(now - 24 * 3600 * 1000 - 10 * 3600 * 1000),
                  start: timestampToDate(now - 24 * 3600 * 1000 - 10 * 3600 * 1000),
                  end: timestampToDate(now - 24 * 3600 * 1000 - 10 * 3600 * 1000),
                  country: 'United States',
                  // platform: 'android',
                  module: "rookie",
              }
          }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/performance`, {params: this.form}).then(res => {
                    this.data = res.data.data.metricList.data;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = d.impression > 100;
                valid &= !this.form.id || (d.image_id + '').includes(this.form.id);
                return valid;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

</style>
<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="姓名" width="200" prop="name"></el-table-column>
        <el-table-column label="用户名" width="200" prop="username"></el-table-column>
        <el-table-column label="密码" width="200" prop="password"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :loading="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button :loading="loading" type="danger" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="姓名" prop="name" :rules="[{required: true, message: '请输入姓名'}]">
                <el-input :disabled="loading" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username" :rules="[{required: true, message: '请输入用户名'}]">
                <el-input :disabled="loading" v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" :rules="[{required: true, message: '请生成密码'}]">
                <el-input :disabled="loading" v-model="form.password" style="width: 90%"></el-input>
                <el-icon size="22" style="cursor: pointer;" @click="generatePassword">
                    <Refresh/>
                </el-icon>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../libs/utils";
import {ElMessageBox} from 'element-plus';

export default {
    name: "PartTime",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                name: '',
                username: '',
                password: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/part-time/user`).then(res => {
                this.data = res.data.data.userList;
                this.loading = false;
            });
        },
        generatePassword() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
            let password = '';

            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                password += characters[randomIndex];
            }
            this.form.password = password;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/part-time/user/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/part-time/user`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/part-time/user/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>
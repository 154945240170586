<template>
    <el-dialog v-model="dialog_opened" width="80%" :title="collection?collection.i18n_name.ZH_HANS:''">
        <h4>共{{ data.length }}张</h4>
        <el-table :data="data" v-loading="loading">
            <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
            <el-table-column label="图片" width="230">
                <template #default="scope">
                  <el-image lazy :src="`${$root.cdn_url}/${scope.row.thumbnail}`"
                            style="width: 100px"></el-image>
                  <el-image lazy :src="`${$root.cdn_url}/${scope.row.finished}`"
                            style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="150">
              <template #default="scope">
                <span style="color: var(--el-color-danger)" v-if="[2].includes(scope.row.status)">
                        {{ image_status_enum[scope.row.status] }}</span>
                <span style="color: var(--el-color-success)"
                      v-else-if="scope.row.status === 4 && scope.row.online_date <= timestampToDate(Date.now())">
                        已上线</span>
                <el-select v-model="scope.row.status" :disabled="loading"
                           v-else-if="[3, 4, 5].includes(scope.row.status)"
                           @change="update(scope.row)">
                  <el-option :value="3" label="测试中"></el-option>
                  <el-option :value="5" label="不通过"></el-option>
                  <el-option :value="4" label="通过"></el-option>
                </el-select>
                <span style="color: var(--el-color-info)" v-else>
                        {{ image_status_enum[scope.row.status] || '正在排队' }}</span>
                <span style="color: var(--el-color-warning)"
                      v-if="[3].includes(scope.row.status) && !scope.row.origin_mask1">
                        等待排序</span>
                <span style="color: var(--el-color-success)" v-if="scope.row.good">建议通过</span>
                <span style="color: var(--el-color-error)" v-else-if="scope.row.good===false">建议不通过</span>
                <el-progress v-if="scope.row.status === 1" :text-inside="true" :stroke-width="16"
                             :percentage="scope.row.progress">
                </el-progress>
              </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button type="success" circle icon="Top" :loading="loading" size="small"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button type="danger" :disabled="loading" @click="remove(scope.row)">从集合中删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-table :data="image_list" style="margin-top: 10px">
          <el-table-column label="预览图" prop="prefix" width="230">
            <template #default="scope">
              <el-image lazy :src="`${$root.cdn_url}/${scope.row.thumbnail}`"
                        style="width: 100px"></el-image>
              <el-image lazy :src="`${$root.cdn_url}/${scope.row.finished}`"
                        style="width: 100px"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="id" prop="id" width="150"></el-table-column>
          <el-table-column label="nick" prop="nickname" width="60"></el-table-column>
          <el-table-column label="状态" width="150">
            <template #default="scope">
              <span style="color: var(--el-color-danger)" v-if="[2].includes(scope.row.status)">
                      {{ image_status_enum[scope.row.status] }}</span>
              <span style="color: var(--el-color-success)"
                    v-else-if="scope.row.status === 4 && scope.row.online_date <= timestampToDate(Date.now())">
                      已上线</span>
              <el-select v-model="scope.row.status" :disabled="loading"
                         v-else-if="[3, 4, 5].includes(scope.row.status)"
                         @change="update(scope.row)">
                <el-option :value="3" label="测试中"></el-option>
                <el-option :value="5" label="不通过"></el-option>
                <el-option :value="4" label="通过"></el-option>
              </el-select>
              <span style="color: var(--el-color-info)" v-else>
                      {{ image_status_enum[scope.row.status] || '正在排队' }}</span>
              <span style="color: var(--el-color-warning)"
                    v-if="[3].includes(scope.row.status) && !scope.row.origin_mask1">
                      等待排序</span>
              <span style="color: var(--el-color-success)" v-if="scope.row.good">建议通过</span>
              <span style="color: var(--el-color-error)" v-else-if="scope.row.good===false">建议不通过</span>
              <el-progress v-if="scope.row.status === 1" :text-inside="true" :stroke-width="16"
                           :percentage="scope.row.progress">
              </el-progress>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="type" width="60">
            <template #default="scope">
              {{ image_type_enum[scope.row.type] }}
            </template>
          </el-table-column>
          <el-table-column label="色块" prop="cells" width="60"></el-table-column>
          <el-table-column label="难度" prop="difficulty" width="60">
            <template #default="scope">
              <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template #default="scope">
              <el-button type="primary" :disabled="scope.row.added"
                         @click="addToCollection(scope.row, scope.row.id)">
                {{ scope.row.added ? '已添加' : '添加到合集' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
            <el-button type="primary" :loading="loading" @click="createImage">向集合中添加图片</el-button>
        </div>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {ElMessageBox, ElMessage} from 'element-plus'
import {timestampToDate} from "../libs/utils";

export default {
    name: "CollectionImage",
    data() {
        return {
            loading: false, dialog_opened: false, data: [], collection: null, image_list: [],
            image_type_enum: {1: '单色', 2: '彩绘'},
            image_status_enum: {1: '处理中', 2: '错误', 5: '未通过', 3: '测试中', 4: '通过'},
        }
    },
    // inject: ['major_map', 'minor_map', 'assistant_map'],
    methods: {
        init(collection) {
            this.collection = collection;
            this.$nextTick(_ => {
                this.dialog_opened = this.loading = true;
                axios.get(`/cms/v1/collection/${collection.id}/image`).then(res => {
                    this.data = res.data.data.imageList;
                    this.loading = false;
                })
                axios.get(`/cms/v1/image?module=5`).then(res => {
                  this.image_list = res.data.data.imageList;
                })
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要从集合中删除该图片吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/collection/${this.collection.id}/image`, {data: {image_id: row.image_id}}).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        createImage() {
            ElMessageBox.prompt('请输入图片id', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                inputPattern: /[a-z0-9]{24}/,
                inputErrorMessage: '请输入合法id',
            }).then(({value}) => {
              this.addToCollection(null, value)
            }).catch(_ => {})
        },
        timestampToDate(timestamp) {
          return timestampToDate(timestamp)
        },
        addToCollection(image, id) {
          this.loading = true;
          axios.post(`/cms/v1/collection/${this.collection.id}/image`, {image_id: id}).then(res => {
            if(image) {
              image.added = true
            }
            this.data.push(res.data.data);
            this.resort();
            this.loading = false;
          }).catch(err => {
            ElMessage.error(err.response.data.status.message);
            this.loading = false;
          })
        },
        update(row) {
          this.loading = true;
          axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
            this.loading = false;
          })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/collection/image`, {sort}).then(_ => {
            })
        },
    },
}
</script>

<style scoped>

</style>
<template>
    <div>
      <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-card shadow="never" v-for="(item, i) in data" style="margin-top: 10px">
      <template #header>
        <div class="card-header">
          <div>
            <span class="title" style="color: #555555; font-size: 18px;"><strong>{{  item.year }}</strong></span>
          </div>
        </div>
      </template>
      <el-row :gutter="10">
        <el-col :span="4" v-for="month in 12" :key="month" class="el-col">
          <div>
            <span>{{ month }} 月 </span>
          </div>
          <div style="margin-top: 10px;" class="grid-container"
               @click="createChallenge(item.year, month, getChallengeImage(item, month))">
            <div v-if="hasChallenge(item, month)">
              <el-image
                  :src="getChallengeThumbnail(item, month)"
                  fit="cover"
                  style="height: 80px">
              </el-image>
              <el-image
                  :src="getChallengeFinished(item, month)"
                  fit="cover"
                  style="height: 80px">
              </el-image>
            </div>
            <el-icon v-else :size="25" :color="'#aaaaaa'">
              <Plus />
            </el-icon>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog v-model="dialog_opened" @close="reset" width="50%">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="年" prop="year" :rules="[{required: true, message: '请选择年份'}]">
              <el-select v-model="form.year" filterable placeholder="请选择" :disabled="loading" style="width: 140px">
                <el-option v-for="c in [2024,2025,2026]" :value="c" :label="c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="月" prop="month" :rules="[{required: true, message: '请选择月份'}]">
              <el-select v-model="form.month" filterable placeholder="请选择" :disabled="loading" style="width: 140px">
                <el-option v-for="c in [1,2,3,4,5,6,7,8,9,10,11,12]" :value="c" :label="c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片ID" prop="image_id" :rules="[{required: true, message: '请输入图片ID'},
                                                              { pattern: '^[a-z0-9]{24}$', message: '图片ID格式不正确'}]">
              <el-input v-model="form.image_id" :disabled="loading" style="width: 200px"></el-input>
            </el-form-item>
        </el-form>
        <el-table :data="image_list" style="margin-top: 10px">
          <el-table-column label="预览图" prop="prefix" width="230">
            <template #default="scope">
              <el-image lazy :src="`${$root.cdn_url}/${scope.row.thumbnail}`"
                        style="width: 100px"></el-image>
              <el-image lazy :src="`${$root.cdn_url}/${scope.row.finished}`"
                        style="width: 100px"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="id" prop="id" width="150"></el-table-column>
          <el-table-column label="nick" prop="nickname" width="60"></el-table-column>
          <el-table-column label="类型" prop="type" width="60">
            <template #default="scope">
              {{ image_type_enum[scope.row.type] }}
            </template>
          </el-table-column>
          <el-table-column label="色块" prop="cells" width="60"></el-table-column>
          <el-table-column label="难度" prop="difficulty" width="60">
            <template #default="scope">
              <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template #default="scope">
              <el-button type="primary"
                         @click="addChallenge(scope.row.id)">
                {{ form.image_id === scope.row.id ? '已' : '' }}选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
            <el-button type="danger" :loading="loading" @click="remove">删除</el-button>

        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';

export default {
    name: "DailyChallenge",
    data() {
        return {
            loading: false, data: [], image_list: [], date_map: {}, dialog_opened: false,
            image_type_enum: {1: '单色', 2: '彩绘'},
            image_tag_enum: {
              1: 'Special',
              2: 'Wallpaper',
              3: 'Collection',
              4: 'Artist',
              5: 'Music',
              6: 'Magic',
              7: 'Hot'
            },
            form: {
                year: '',
                month: '',
                image_id: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/daily-challenge`).then(res => {
                    this.data = res.data.data.challenge_list
                }),
                axios.get(`/cms/v1/image?module=4`).then(res => {
                    this.image_list = res.data.data.imageList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog() {
            this.dialog_opened = true;
            this.$nextTick(_ => {
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.form = {
              year: '',
              month: '',
              image_id: '',
            }
        },
        addChallenge(image_id) {
          this.form.image_id = image_id
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post(`/cms/v1/daily-challenge`, this.form).then(_ => {
                      this.reset();
                      this.init()
                    })
                }
            })
        },
        remove() {
          this.$refs.form.validate(valid => {
            if (valid) {
              this.loading = true;
              axios.delete(`/cms/v1/daily-challenge`,{params: {year: this.form.year, month:this.form.month}}).then(_ => {
                this.reset();
                this.init()
              })
            }
          })
        },
        createChallenge(year, month, image_id) {
          this.form.year = year
          this.form.month = month
          this.form.image_id = image_id
          this.openDialog()
        },
        hasChallenge(item, month) {
          return item.challenges.some(challenge => parseInt(challenge.month) === month)
        },
        getChallengeImage(item, month) {
          const challenge = item.challenges.find(c => parseInt(c.month) === month)
          return challenge ? challenge.image_id : ''
        },
        getChallengeThumbnail(item, month) {
            const challenge = item.challenges.find(c => parseInt(c.month) === month)
            return challenge ? challenge.thumbnail : ''
        },
        getChallengeFinished(item, month) {
          const challenge = item.challenges.find(c => parseInt(c.month) === month)
          return challenge ? challenge.finished : ''
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: fit-content;
    width: 160px;
    min-height: var(--el-calendar-cell-width);
}

.card-header {
  display: flex;
  justify-content: space-between;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  line-height: 24px;
}

.el-col {
  border: 1px solid #e6e6e6;
  border-right: none;
  border-bottom: none;
  padding: 10px;
  text-align: center;
}

.el-col:nth-child(6n) {
  border-right: 1px solid #e6e6e6;
}

.el-col:nth-last-child(-n+6) {
  border-bottom: 1px solid #e6e6e6;
}

.grid-container {
  margin-top: 10px;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100px;
}

</style>